<template>
	<div class="flex items-center justify-center" v-if="$config.public.hCaptchaSiteKey">
		<VueHcaptcha ref="hCaptcha" :sitekey="$config.public.hCaptchaSiteKey" @verify="onVerifyCaptcha" theme="dark" />
	</div>
</template>

<script setup lang="ts">
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'

const emit = defineEmits<{
	(e: 'onVerify', token: string): void
}>()

const hCaptchaRef = ref<any>(null)

const onVerifyCaptcha = (token: string) => {
	emit('onVerify', token)
}

const reset = () => {
	hCaptchaRef.value?.reset()
}
</script>
